import { Box } from "@material-ui/core";
import "./index.scss";
import { Flip, Slide } from "react-awesome-reveal";

const AboutUsPage = () => {
  return (
    <>
      <Box className="about-us-page-container">
        <Slide duration={2000}>
          <Box className="about-us-types-container">
            <Box className="about-us-images-wrapper">
              <img src="icon-mission.svg" width={"330px"} alt="Mission Icon" />
            </Box>
            <Box>
              <h1 className="about-us-types-headers">
                Revolutionizing Online Trading
              </h1>
              <span className="about-us-types-spans">
                We provide innovative solutions designed for both novice traders
                and seasoned professionals. Our focus is on education, offering
                a comprehensive library of VOD seminars, webinars, daily news,
                and market analysis. With 24/7 customer support and expert
                financial advice, your success is at the core of our mission.
              </span>
            </Box>
          </Box>
        </Slide>

        <Slide duration={2000} direction="right">
          <Box className="about-us-types-container-reverse">
            <Box className="risk-text">
              <h1 className="about-us-types-headers">Risk Management</h1>
              <span className="about-us-types-spans">
                Aquila Markets has built a strong reputation in the industry.
                Our traders and financial experts are key to our success in
                highly volatile markets. Through professional risk management
                strategies, we minimize risks and maximize returns for our
                clients. Profits are fairly shared between the fund, private
                investors, and clients.
              </span>
            </Box>
            <Box className="about-us-images-wrapper">
              <img src="icon-risk-management.svg" alt="Risk Management Icon" />
            </Box>
          </Box>
        </Slide>

        <Slide duration={2000}>
          <Box className="about-us-types-container">
            <Box className="about-us-images-wrapper">
              <img src="icon-team.svg" width={"330px"} alt="Team Icon" />
            </Box>
            <Box>
              <h1 className="about-us-types-headers">
                Relationship Management
              </h1>
              <span className="about-us-types-spans">
                Our experienced team of Financial Analysts is committed to
                providing personalized, long-term relationships with clients. We
                believe that premium service and attention to detail are
                essential for managing successful portfolios. Together, we
                achieve more.
              </span>
            </Box>
          </Box>
        </Slide>

        <Slide direction="right" duration={2000}>
          <Box className="about-us-types-container-reverse">
            <Box>
              <h1 className="about-us-types-headers">Advanced Security</h1>
              <span className="about-us-types-spans">
                In the financial markets, security is crucial. That’s why we
                implement advanced security protocols like SSL encryption, PCI
                DSS compliance, Comodo Certification Authority, and Peer-to-Peer
                Encryption to ensure a safe trading environment. Your security
                is our top priority.
              </span>
            </Box>
            <Box className="about-us-images-wrapper">
              <img src="icon-shield.svg" width={"330px"} alt="Shield Icon" />
            </Box>
          </Box>
        </Slide>
      </Box>
    </>
  );
};

export default AboutUsPage;
