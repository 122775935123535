import { Box, Container, Grid, Link } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router";
import PaymentFooter from "./components/PaymentFooter";

import "./index.scss";
function Footer() {
  const navigate = useNavigate();
  const mobileWidth = window.innerWidth;
  //asd
  return (
    <div className="footer-container">
      <Box className="footer-inner-container">
        <Container maxWidth="xl">
          <Grid container>
            {/* <LegalFooter /> */}
            <PaymentFooter />
            <Grid item className="grid-inner-container">
              <p>
                <strong className="footer-strong">RISK NOTICE</strong>
                <br></br>
                CFDs are high-risk leveraged products that can lead to the total
                loss of your invested capital. These products may not be
                suitable for all investors. You should only trade with money you
                can afford to lose. Before engaging in any trading activity,
                make sure you understand the risks involved, evaluate your
                experience level, and consult independent financial advice if
                needed. We at Aquila Markets shall not be held liable for any
                losses or damages, whether direct, indirect, or consequential,
                arising from CFD transactions.
              </p>
            </Grid>
          </Grid>
          <Box className="footer-container-box">
            <Box onClick={() => navigate("/privacy")}>
              <Link className="footer-container-link">Privacy Policy</Link>
            </Box>
            <Box onClick={() => navigate("/terms")}>
              <Link className="footer-container-link">Terms & Conditions</Link>
            </Box>
            <Box onClick={() => navigate("/aml")}>
              <Link className="footer-container-link">AML Policy</Link>
            </Box>
            <Box onClick={() => navigate("/deposit-policy")}>
              <Link className="footer-container-link">Deposit Policy</Link>
            </Box>
            <Box onClick={() => navigate("/refund-policy")}>
              <Link className="footer-container-link">Refund Policy</Link>
            </Box>
            <Box onClick={() => navigate("/security-policy")}>
              <Link className="footer-container-link">Security Policy</Link>
            </Box>
            <Box onClick={() => navigate("/risk-disclaimer")}>
              <Link className="footer-container-link">Risk Disclaimer</Link>
            </Box>
            <Box onClick={() => navigate("/kyc")}>
              <Link className="footer-container-link">KYC Policy</Link>
            </Box>
            <Box onClick={() => navigate("/security")}>
              <Link className="footer-container-link">Security</Link>
            </Box>
            <Box onClick={() => navigate("/mobile-privacy")}>
              <Link className="footer-container-link">Mobile Privacy</Link>
            </Box>
          </Box>
          <Box className="location-box-container">
            <span>Aquila Markets</span>
          </Box>
        </Container>
      </Box>
    </div>
  );
}

export default Footer;
