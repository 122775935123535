import { Box, Button } from "@material-ui/core";
import { useNavigate } from "react-router";
import "./index.scss";
import { Slide } from "react-awesome-reveal";

const ToolsPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Box
        style={{ paddingBottom: "5%" }}
        className={
          window.location.href.includes("tools")
            ? "tools-page-container-landing"
            : "tools-page-container"
        }
      >
        {/* Section for Zero Commission */}
        <Slide duration={2000}>
          <Box className="tools-types-container">
            <Box className="tools-types-risk-img">
              <img src="icon-zero-commission.svg" alt="Zero Commission" />
            </Box>
            <Box className="tools-types-wrapper">
              <h1 className="tools-types-headers">Close to 0% Commission</h1>
              <span className="tools-types-spans">
                Step into the global markets and explore endless opportunities
                with Aquila Markets—all without paying any commission.
              </span>
              <br />
              <br />
              <span className="tools-types-spans">
                Please note that zero commission is available to Aquila Markets
                clients only and does not apply to short or leveraged stock
                trades. While there's no broker fee for opening or closing
                positions, other fees may apply.
              </span>
              <br />
              <br />
              <div className="button-wrap">
                <Button
                  variant="outlined"
                  color="inherit"
                  className="tools-buttons"
                  onClick={() => navigate("/sign-up")}
                >
                  Join Now
                </Button>
              </div>
            </Box>
          </Box>
        </Slide>

        {/* Section for Risk Management */}
        <Slide duration={2000} direction="right">
          <Box className="tools-types-container-2">
            <Box style={{ marginTop: "30px" }} className="tools-types-risk-img">
              <img src="icon-risk-management.svg" alt="Risk Management" />
            </Box>
            <Box className="tools-types-wrapper">
              <h1 className="tools-types-headers">Risk Management</h1>
              <span className="tools-types-spans">
                At Aquila Markets, we offer a robust mix of technical and
                fundamental analysis, tailored account types, instant execution,
                and specialized software to help you manage risk effectively.
              </span>
              <br />
              <br />
              <span className="tools-types-spans">
                With tools like Stop Loss and Take Profit, you can control both
                your potential losses and profits at levels you set.
              </span>
              <br />
              <br />
              <span className="tools-types-spans">
                Remember, managing risk is vital to your investment strategy.
                Always make use of our risk management tools, in addition to
                expert guidance from your personal financial analyst.
              </span>
              <br />
              <br />
              <div className="button-wrap">
                <Button
                  color="inherit"
                  variant="outlined"
                  className="tools-buttons"
                  onClick={() => navigate("/sign-up")}
                >
                  Join Now
                </Button>
              </div>
            </Box>
          </Box>
        </Slide>
      </Box>
    </>
  );
};

export default ToolsPage;
