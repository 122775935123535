import { Box } from "@material-ui/core";
import "./index.scss";
import Reveal from "react-awesome-reveal";
import { CryptocurrencyMarket, MarketOverview } from "react-tradingview-embed";

const PlatformPage = () => {
  return (
    <>
      <div
        style={{
          marginTop: window.location.href.includes("platform") ? "3rem" : "0",
        }}
        className="platform-page-video-container"
      ></div>

      <Box className="platform-page-container">
        <Box
          style={{ display: "flex" }}
          className="platform-page-inner-container"
        >
          <Reveal className="platform-page-reveal-container" duration={2000}>
            <Box className="platform-page-textBox">
              <h3 className="platform-page-h3">
                Trade the World's Leading Assets with Our High-Tech Platforms
              </h3>
              <Box className="platform-page-span">
                <span>
                  Aquila Markets is committed to providing seamless online
                  trading experiences for global traders. Whether you're trading
                  CFDs (Contracts for Differences) or exploring other financial
                  instruments, we have you covered.
                  <br />
                  <br />
                  Our platform is designed to simplify your trading journey
                  while ensuring security. With Aquila Markets, you can access a
                  wide range of financial instruments, from commodities to
                  cryptocurrencies—all in one place.
                </span>
              </Box>
            </Box>
          </Reveal>

          <Box style={{ alignSelf: "center", marginTop: "5%" }}>
            <MarketOverview
              widgetProps={{
                theme: "dark",
                showChart: true,
                locale: "en",
                width:
                  window.innerWidth <= 667
                    ? 400
                    : window.innerWidth >= 1050 && window.innerWidth <= 1300
                    ? 500
                    : 600,
                height: window.innerWidth <= 667 ? 500 : 600,
              }}
            />
          </Box>
        </Box>

        <Box
          className="platform-page-crypto-chart-container"
          style={{ display: "flex", paddingLeft: "5%" }}
        >
          <CryptocurrencyMarket
            widgetProps={{
              width:
                window.innerWidth <= 667
                  ? 400
                  : window.innerWidth >= 1050 && window.innerWidth <= 1300
                  ? 800
                  : 900,
              theme: "dark",
              showChart: true,
              locale: "en",
            }}
          />
          <img
            src="trading-view.png"
            width={450}
            height={450}
            style={{ alignSelf: "center", marginLeft: "5%" }}
            alt="Trading view"
          />
        </Box>
      </Box>
    </>
  );
};

export default PlatformPage;
