import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import "./index.scss";

const FaqPage = () => {
  return (
    <>
      <Box
        style={{
          marginTop: window.location.href.includes("faq") ? "3rem" : "0",
        }}
        className="faq-page-container"
      >
        <Box className="faq-page-textBox">
          <h3 className="faq-page-h3">Frequently Asked Questions</h3>
          <Box className="faq-page-span">
            <span>
              Got questions? We've got answers. Below, you'll find helpful
              information on everything you need to know to get started with
              Aquila Markets.
            </span>
          </Box>
        </Box>

        <Box className="faq-types-container-main">
          {/* First Set of FAQs */}
          <Box className="faq-types-container">
            {/* Question 1 */}
            <Box>
              <Accordion className="faq-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMore style={{ color: "black" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="faq-typography-header">
                    Are my funds ensured and protected?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faq-typography">
                    Customer funds are held in segregation under Commodity
                    Futures Trading Commission rule 1.25 (“CFTC 1.25”). All our
                    clearing firms fully comply with this key customer
                    protection rule. While segregation offers safety, no
                    regulation can fully protect against fraud. You can check
                    the government FOCUS reports for further details.{" "}
                    <span
                      style={{
                        cursor: "pointer",
                        fontVariant: "petite-caps",
                        color: "white",
                        marginLeft: "0.5rem",
                        marginRight: "0.5rem",
                      }}
                      onClick={() =>
                        window.open(
                          "https://www.cftc.gov/sites/default/files/sites/default/files/idc/groups/public/%40requestsandactions/documents/ifdocs/08%20-%20FCM%20Webpage%20Update%20-%20August%202019.pdf"
                        )
                      }
                    >
                      CLICK HERE
                    </span>
                    to check the Financial Data for FCMs.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
            {/* Question 2 */}
            <Box>
              <Accordion className="faq-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="faq-typography-header">
                    What financial products can I trade with you?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faq-typography">
                    Aquila Markets provides a wide range of instruments,
                    including currency pairs, commodities, equity indices, and
                    cryptocurrencies. With over 60+ instruments available, you
                    can diversify your trading portfolio easily.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
            {/* Question 3 */}
            <Box>
              <Accordion className="faq-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="faq-typography-header">
                    How do I withdraw funds?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faq-typography">
                    To withdraw funds, follow these simple steps:
                    <br />
                    1. Log into your account on aquila-markets.com.
                    <br />
                    2. Navigate to the "Wallet" section and select "Withdraw."
                    <br />
                    3. Complete the withdrawal form and click "Send."
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
            {/* Question 4 */}
            <Box>
              <Accordion className="faq-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="faq-typography-header">
                    How do I make a deposit?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faq-typography">
                    To make a deposit, follow these steps:
                    <br />
                    1. Log into your account on aquila-markets.com.
                    <br />
                    2. Navigate to the "Wallet" section and select "Deposit."
                    <br />
                    3. Choose your deposit method, enter the amount, and follow
                    the instructions.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>

          {/* Second Set of FAQs */}
          <Box className="faq-types-container">
            {/* Question 5 */}
            <Box>
              <Accordion className="faq-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="faq-typography-header">
                    What withdrawal methods are available?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faq-typography">
                    Aquila Markets offers several methods for withdrawing funds:
                    <br />- Bank Transfer
                    <br />- Cryptocurrencies
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
            {/* Question 6 */}
            <Box>
              <Accordion className="faq-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="faq-typography-header">
                    What deposit methods are available?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faq-typography">
                    Aquila Markets accepts the following deposit methods:
                    <br />- Bank Transfer
                    <br />- Debit/Credit Card (Visa or MasterCard only)
                    <br />- Bitcoin
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
            {/* Question 7 */}
            <Box>
              <Accordion className="faq-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="faq-typography-header">
                    How can I contact Aquila Markets?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faq-typography">
                    You can contact Aquila Markets via email, phone, or live
                    chat. For more details, please visit our
                    <span
                      style={{
                        cursor: "pointer",
                        fontVariant: "petite-caps",
                        color: "red",
                        marginLeft: "0.5rem",
                        marginRight: "0.5rem",
                      }}
                      onClick={() =>
                        window.open("https://aquila-markets.com/contact-us")
                      }
                    >
                      CONTACT PAGE
                    </span>
                    .
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
            {/* Question 8 */}
            <Box>
              <Accordion className="faq-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="faq-typography-header">
                    How do I receive account updates?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faq-typography">
                    You will receive daily and monthly statements in your online
                    portal after registering and funding your account.
                    Additionally, you will receive updates via phone or email.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default FaqPage;
