import { Box } from "@material-ui/core";
import "./index.scss";
import { Slide } from "react-awesome-reveal";

const FeaturesPage = () => {
  return (
    <>
      <Box
        style={{
          marginTop: window.location.href.includes("features") ? "3rem" : "0",
        }}
        className="features-page-container"
      >
        <Box className="features-page-textBox">
          <h3 className="features-page-h3">What Do We Offer?</h3>
          <Box className="features-page-span">
            <span>
              At Aquila Markets, we provide our clients with state-of-the-art
              tools and services, ensuring their success in the dynamic world of
              trading.
            </span>
          </Box>
        </Box>

        <Box className="features-types-container">
          <Slide duration={2000}>
            <Box className="features-types-single-container">
              <img src="icon-wallet.svg" width={"140px"} alt="Wallet Icon" />
              <h2 className="features-types-headers">Segregated Wallet</h2>
              <span className="features-types-spans">
                Store your funds securely with the most advanced technology in
                the market.
              </span>
            </Box>
          </Slide>

          <Slide duration={2000}>
            <Box className="features-types-single-container">
              <img src="icon-advisor.svg" width={"140px"} alt="Advisor Icon" />
              <h2 className="features-types-headers">
                Personal Financial Advisor
              </h2>
              <span className="features-types-spans">
                Gain access to a dedicated financial analyst to help guide you
                through the markets.
              </span>
            </Box>
          </Slide>

          <Slide duration={2000}>
            <Box className="features-types-single-container">
              <img
                src="icon-portfolio.svg"
                width={"140px"}
                alt="Portfolio Icon"
              />
              <h2 className="features-types-headers">Portfolio Management</h2>
              <span className="features-types-spans">
                Manage all your investments in one platform that aligns your
                portfolio with your goals.
              </span>
            </Box>
          </Slide>
        </Box>

        <Box className="features-types-container">
          <Slide duration={2000} direction="right">
            <Box className="features-types-single-container">
              <img
                src="icon-financial-tools.svg"
                width={"140px"}
                alt="Financial Tools Icon"
              />
              <h2 className="features-types-headers">Advanced Market Tools</h2>
              <span className="features-types-spans">
                Leverage cutting-edge tools to minimize risks and maximize your
                profit margins through effective risk management.
              </span>
            </Box>
          </Slide>

          <Slide duration={2000} direction="right">
            <Box className="features-types-single-container">
              <img src="icon-support.svg" width={"140px"} alt="Support Icon" />
              <h2 className="features-types-headers">Customer Support</h2>
              <span className="features-types-spans">
                Access live support via phone or email, available 24/5 to assist
                you.
              </span>
            </Box>
          </Slide>

          <Slide duration={2000} direction="right">
            <Box className="features-types-single-container">
              <img src="icon-shield.svg" width={"140px"} alt="Security Icon" />
              <h2 className="features-types-headers">Top-Tier Security</h2>
              <span className="features-types-spans">
                Enjoy peace of mind knowing we actively monitor and secure your
                account to prevent unauthorized access.
              </span>
            </Box>
          </Slide>
        </Box>
      </Box>
    </>
  );
};

export default FeaturesPage;
