import { Box, Button } from "@material-ui/core";
import "./index.scss";
import { Slide } from "react-awesome-reveal";
import {
  AssignmentIndOutlined,
  AttachMoneyOutlined,
  VerifiedUserOutlined,
} from "@material-ui/icons";
import { useNavigate } from "react-router";

const HowItWorksPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Box
        style={{
          marginTop: window.location.href.includes("how-it-works")
            ? "3rem"
            : "0",
        }}
        className="how-it-works-page-container"
      >
        <Box className="how-it-works-page-textBox">
          <h3 className="how-it-works-page-h3">How It Works</h3>
          <Box className="how-it-works-page-span">
            <h5>Getting started with Aquila Markets is simple and quick:</h5>
          </Box>
        </Box>

        {/* Step 1: Enter Personal Details */}
        <Slide
          duration={2000}
          style={{ alignSelf: window.innerWidth <= 658 ? "center" : "start" }}
          className="personal-details-container"
        >
          <Box className="how-it-works-types-single-container">
            <AssignmentIndOutlined
              style={{ color: "#FF6101", width: "80px", height: "80px" }}
            />
            <h2 className="how-it-works-types-headers">
              Enter Personal Details
            </h2>
            <span className="how-it-works-types-spans">
              Fill in your personal details through our secure online form. It’s
              quick and easy – only takes about 5 minutes.
            </span>
          </Box>
        </Slide>

        {/* Step 2: Verify Your Identity */}
        <Slide duration={2000} className="verify-account-container">
          <Box className="how-it-works-types-single-container">
            <VerifiedUserOutlined
              style={{ color: "#FF6101", width: "80px", height: "80px" }}
            />
            <h2 className="how-it-works-types-headers">Verify Your Identity</h2>
            <span className="how-it-works-types-spans">
              We just need to confirm your identity by submitting 3 types of ID
              documentation. This step ensures the security of your account.
            </span>
          </Box>
        </Slide>

        {/* Step 3: Fund Your Account and Start Trading */}
        <Slide
          duration={2000}
          style={{ alignSelf: window.innerWidth <= 658 ? "center" : "end" }}
        >
          <Box className="how-it-works-types-single-container">
            <AttachMoneyOutlined
              fontSize="large"
              style={{ color: "#FF6101", width: "80px", height: "80px" }}
            />
            <Button
              className="how-it-works-types-headers"
              style={{
                textShadow: "none",
                color: "black",
                fontWeight: "bold",
                fontSize: "1.2rem",
                backgroundColor: "#FF6101",
                letterSpacing: "0.5px",
                fontFamily: '"Tajawal", sans-serif',
              }}
              sx={{
                my: 2,
                color: "white",
                fontWeight: "bold",
                display: "block",
              }}
              onClick={() => navigate("/sign-up")}
            >
              Start Trading Now
            </Button>
            <span className="how-it-works-types-spans">
              Fund your account and choose from over 250+ financial products to
              begin trading.
            </span>
          </Box>
        </Slide>
      </Box>
    </>
  );
};

export default HowItWorksPage;
